import React from "react"
import Layout from "../components/layout"
import "../styles/styles.scss"
import me from "./me-circle2.png"
import { Link, graphql } from "gatsby"
import { FaTwitter } from 'react-icons/fa'
import _ from "lodash"
import { Helmet } from "react-helmet"


export default function Index({ data }) {
    const { edges: posts } = data.allMarkdownRemark
    return (
        <Layout>
            <Helmet title="Jessa Sparks | Home" />
            <div className="row">
              <div className="col-sm-10">
                  <h2>Recent Posts</h2>
                  { 
                    posts.filter(post => post.node.frontmatter.title.length > 0)
                      .map(({ node: post }) => {
                        return (
                          <div key={post.id} className="card my-3">
                            <div className="card-body">
                                <h3 className="card-title">
                                    <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
                                </h3>
                                <p><small>{post.frontmatter.date}</small></p>
                                <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                                <p className="font-weight-bold">
                                  <Link to={post.frontmatter.path}>Read more</Link>
                                </p>

                                <p>Tags: {post.frontmatter.tags.map((tag, index) => {
                                    return (
                                      <span key={index}>
                                          <Link className="tag" to={`/tags/${_.kebabCase(tag)}/`}> {tag}</Link>
                                          { index !== post.frontmatter.tags.length - 1 ? ',' : '' }
                                      </span>
                                    )
                                  })
                                }</p>
                            </div>
                          </div>
                        )
                      }
                    )
                  }
              </div>

              <div className="col-sm-2 text-center">
                <img src={me} width="150" height="150" alt="Me"/>
                <p><strong>
                  <a href="https://twitter.com/jessasparks"><FaTwitter/> @jessasparks</a>
                  </strong>
                </p>
                <p><strong>Developer, Mom, Runner</strong></p>
              </div>
          </div>
        </Layout>
  )
}
export const pageQuery = graphql`
  query PreviewQuery {
    allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 500, format: HTML)
          id
          html
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
          }
        }
      }
    }
  }
`